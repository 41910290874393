import './bookmark.scss';
import { createElementFromHTML } from '../../utilities/js/helper';

class Bookmark {
    constructor () {
        this.localStorage = [];
        this.localBookmarks = [];
        this.$mainBookmarksLabel = document.querySelector('[data-main-bookmarks]');
        this.$mainBookmarksList = document.querySelector('[data-main-bookmarks="list"]');
        this.$mainBookmarksCount = document.querySelector('[data-main-bookmarks-count]');
    }

    initialize ($content) {
        this.readStorage();

        const $bookmarks = $content.querySelectorAll('[data-bookmark]');

        if ($bookmarks.length > 0) {
            $bookmarks.forEach((bookmark) => {
                this.setEvent(bookmark);

                if (this.localBookmarks) {
                    if (this.checkBookmark(bookmark.dataset.bookmarkTripcode)) {
                        bookmark.classList.add('is--bookmarked');
                    }
                }
            });
        }
        this.sendToMain();
    }

    // set general events
    setEvent (bookmarkButton) {
        bookmarkButton.addEventListener('click', (e) => {
            e.preventDefault();

            if (bookmarkButton.classList.contains('is--bookmarked')) {
                this.removeBookmark(bookmarkButton.dataset.bookmarkTripcode);
                bookmarkButton.classList.remove('is--bookmarked');
            } else {
                this.setBookmark(bookmarkButton);
                bookmarkButton.classList.add('is--bookmarked');
            }
        });
    }

    sendToMain () {
        if (this.localBookmarks && this.localBookmarks.length > 0 && this.$mainBookmarksList) {
            this.setMainCount(this.localBookmarks.length);
            this.$mainBookmarksList.innerHTML = '';

            const lastBookmarks = this.localBookmarks.slice(-3);

            lastBookmarks.forEach((bookmark) => {
                const $bookmark = createElementFromHTML('' +
                    '<li class="main-nav__bookmarks-item">' +
                    '<a href="' + bookmark.url + '" class="main-nav__bookmarks-link">' +
                    '<figure class="main-nav__bookmarks-image">' +
                    '<img src="' + bookmark.image + '" alt="dummy Image">' +
                    '</figure>' +
                    '<div class="main-nav__bookmarks-content">' +
                    '<p class="main-nav__bookmarks-route">' + bookmark.route + '</p>' +
                    '<p class="main-nav__bookmarks-title">' + bookmark.title + '</p>' +
                    '</div>' +
                    '<div class="main-nav__bookmarks-hover">' +
                    '<button type="button" class="button is--small is--secondary reverse-icon-arrow-right">' + translations.bookmarksLinkToTripLabel + '</button>' + // eslint-disable-line
                    '</div></a></li>');

                this.$mainBookmarksList.appendChild($bookmark);
            });
        } else {
            this.setMainCount(0);

            if (this.$mainBookmarksList) {
                this.$mainBookmarksList.innerHTML = '';
            }
        }
    }

    setMainCount (count) {
        if (this.$mainBookmarksLabel) {
            this.$mainBookmarksLabel.setAttribute('data-main-bookmarks', count);
            this.$mainBookmarksLabel.lastElementChild.textContent = count.toString();
            this.$mainBookmarksCount.firstElementChild.textContent = `${count.toString()} ${(parseInt(count) > 1) ? translations.trips : translations.trip}`; // eslint-disable-line
        }
    }

    readStorage () {
        this.localBookmarks = JSON.parse(localStorage.getItem('bookmarks'));
    }

    setBookmark (bookmark, object) {
        let bookmarkObject = {};

        if (!object) {
            bookmarkObject = {
                route: bookmark.dataset.bookmarkRoute,
                title: bookmark.dataset.bookmarkTitle,
                url: bookmark.dataset.bookmarkUrl,
                tripCode: bookmark.dataset.bookmarkTripcode,
                image: bookmark.dataset.bookmarkImage
            };
        } else {
            bookmarkObject = object;
        }

        const $bookmarks = JSON.parse(localStorage.getItem('bookmarks')) || [];

        $bookmarks.push(bookmarkObject);
        localStorage.setItem('bookmarks', JSON.stringify($bookmarks));
        this.localBookmarks = $bookmarks;
        this.sendToMain();
    }

    removeBookmark (tripCode) {
        const newArr = [];
        this.readStorage();

        for (const bookmark of this.localBookmarks) {
            if (bookmark.tripCode !== tripCode) {
                newArr.push(bookmark);
            }
        }

        localStorage.setItem('bookmarks', JSON.stringify(newArr));
        this.localBookmarks = newArr;

        window.dispatchEvent(new CustomEvent('bookmark.removed', {
            view: window,
            bubbles: true,
            cancelable: false
        }));

        this.sendToMain();
    }

    checkBookmark (tripCode) {
        return this.localBookmarks.some(function (bookmark) {
            return bookmark.tripCode === tripCode;
        });
    }

    clearBookmarks () {
        localStorage.removeItem('bookmarks');
    }
}

export { Bookmark };
