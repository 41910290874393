import './app.scss';
import domready from 'domready';
import 'lazysizes';
import { getOffset } from './utilities/js/helper';
import { loadLazyImages } from './utilities/js/lazy-image-loader';
import { EventScroller } from './utilities/js/event-scroller';
import { ResizeHandler } from './utilities/js/resize-handler';
import { MediaMatchHandler } from './utilities/js/media-match-handler';
import { Bookmark } from './components/bookmark/bookmark';

// GLOBAL OPTIONS
window.OPTIONS = { // eslint-disable-line
    loader: '<span class="loading"><span class="loader"></span></span>',
    breakpoints: {
        s: 320,
        m: 768,
        l: 1024,
        xl: 1280,
        xxl: 1440,
        xxxl: 1920
    }
};

window.eventScroller = new EventScroller();
window.resizeHandler = new ResizeHandler();
window.mediaMatch = new MediaMatchHandler();

window.youtubeApi = false;
window.youtubeApiLoading = false;
window.anchorTimeout = null;
window.bookmark = new Bookmark();
window.accordionLoaded = false;
window.isWaitingForAnchor = false;

const browserCheck = () => {
    window.browser = {
        csshover: !window.matchMedia('(pointer:coarse)').matches,
        ios: (/iPhone|iPad|iPod/i).test(navigator.userAgent),
        iphone: (/iPhone|iPod/i).test(navigator.userAgent),
        android: (/(android)/i).test(navigator.userAgent),
        firefox: (/firefox/i).test(navigator.userAgent),
        safari: (/Version\/[\d.]+.*Safari/).test(navigator.userAgent)
    };

    Object.entries(window.browser).forEach(([key, value]) => {
        document.documentElement.classList.add((!value) ? `no-${key}` : `${key}`);
    });
};

window.checkInView = (entries, observer) => {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            if (!entry.target.classList.contains('inview')) {
                entry.target.classList.add('inview');

                if (typeof entry.target.dataset.footer === 'undefined') {
                    window.inViewObserver.unobserve(entry.target);
                }
            }

            if (typeof entry.target.dataset.footer !== 'undefined') {
                quickAccessSwitch('off');
            }
        } else {
            if (typeof entry.target.dataset.footer !== 'undefined') {
                quickAccessSwitch('on');
            }
        }
    });
};

window.inViewObserver = new IntersectionObserver(window.checkInView, {
    threshold: 0,
    rootMargin: '0px 100% -10% 100%'
});

const $quickAccess = document.querySelector('[data-quick-access="root"]');
const quickAccessSwitch = (state) => {
    if (!$quickAccess) {
        return;
    }

    if (state === 'off') {
        $quickAccess.classList.add('is--hidden');
    }
    if (state === 'on') {
        $quickAccess.classList.remove('is--hidden');
    }
};

const jumpToAnchor = (targetId) => {
    // let anchorTimeout = window.anchorTimeout;
    const $target = document.getElementById(targetId);
    // const $header = document.querySelector('[data-header="root"]');
    // const headerAPI = $header.headerAPI;

    if ($target !== null) {
        const anchorDimensions = getOffset($target);
        const anchorDimensionsTop = anchorDimensions.top;
        console.log(anchorDimensions.top);
        window.scrollTo({
            top: anchorDimensionsTop,
            left: 0,
            behavior: 'smooth'
        });

        /*
        clearTimeout(anchorTimeout);
        anchorTimeout = window.setTimeout(() => {
            if (headerAPI) {
                headerAPI.checkPosition();
            }
        }, 500);
        */
    }
};

const initContent = ($content) => {
    console.log('Application is starting');

    const $$anchor = $content.querySelectorAll('a[href*="#"]:not([data-anchor-navigation])');
    for (let i = 0; i < $$anchor.length; i++) {
        const $anchor = $$anchor[i];

        $anchor.addEventListener('click', (e) => {
            let baseUrl = $anchor.getAttribute('href').split('#')[0];
            const targetId = $anchor.getAttribute('href').split('#')[1];
            const currentUrl = window.location.href;

            if (baseUrl === '') {
                baseUrl = currentUrl;
            }

            if (baseUrl === currentUrl) {
                jumpToAnchor(targetId);
                e.preventDefault();
            }
        });
    }

    window.addEventListener('content.loaded', (e) => {
        if (e.detail.ajax && !window.isWaitingForAnchor) {
            const hash = window.location.hash;
            if (hash) {
                const targetId = hash.split('#')[1];
                let wait = 0;
                window.isWaitingForAnchor = true;

                const waitForAnchor = setInterval(() => {
                    if (document.getElementById(targetId)) {
                        clearInterval(waitForAnchor);

                        setTimeout(() => {
                            jumpToAnchor(targetId);
                            window.location.hash = '';
                        }, 400);
                    } else {
                        wait++;

                        if (wait >= 40) {
                            window.isWaitingForAnchor = false;
                            clearInterval(waitForAnchor);
                        }
                    }
                }, 100);
            }
        }
    });

    if (window.location.hash && window.siteheaderAPI) {
        const targetId = window.location.hash.split('#')[1];
        jumpToAnchor(targetId);
    }

    window.bookmark.initialize(document);

    loadLazyImages();
};

domready(function () {
    const initApplication = () => {
        browserCheck();

        initContent(document);

        window.eventScroller.init();
        window.resizeHandler.init();

        const contentUpdateEvent = new CustomEvent('content.loaded', {
            view: window,
            bubbles: true,
            cancelable: false,
            detail: {
                $context: document
            }
        });

        window.dispatchEvent(contentUpdateEvent);

        window.dataInview = document.querySelectorAll('[data-inview]:not(.inview)');
        setTimeout(() => {
            window.dataInview.forEach((dataInView) => {
                window.inViewObserver.observe(dataInView);
            });
        }, 500);
    };

    initApplication();
});
